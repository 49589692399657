<template>
    <div class="main">
      <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/warning/upgrade.png" alt="">
      <div class="text">标准版不支持该功能，请升级到企业版！</div>
    </div>
</template>

<script>
  // 店铺升级
  export default {
    name: 'upgrade',
    created () {
      this.$emit('changrMeta', true)
    }
  }
</script>

<style scoped lang="scss">
.main{
  height: calc(100vh - 360px);
  min-height: 512px;
  width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;

  img{
    width: 50px;
    height: 50px;
    margin-bottom: 35px;
  }

  .text{
    font-size: 15px;
    color: #333333;
    font-weight: 500;
  }
}
</style>
